import { Injectable } from '@angular/core';
import { CrmLayoutProfileProvider } from 'common-module/layout-v2';
import { CrmLayoutSiderControl } from 'common-module/layout-v2/helpers/layout-sider-control';

import { isUserAdmin } from '../user/helpers/is-user-admin';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class LayoutProfileProvider extends CrmLayoutProfileProvider {
  override userService!: UserService;

  protected override handleProfileItem(options: {
    event: MouseEvent;
    siderControl?: CrmLayoutSiderControl;
  }) {
    this.userService.user$.subscribe((loggedUser) => {
      if (isUserAdmin(loggedUser)) {
        this.router.navigate(['users', loggedUser._id]).then(() => {});
      } else {
        super.handleProfileItem(options);
      }
    });
  }
}
